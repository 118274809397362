import { ReactNode, useRef, VFC } from 'react';
import { Box, Button, Container, Divider, Grid, Heading, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import Author from 'components/common/author';
import { useTranslation } from 'lib/hooks/useTranslation';
import { Locale, Storyblok } from 'types';
import NextLink from 'next/link';
import { containerHeight as headerHeight } from 'components/layout/header/styles';
import HubSpotCTA, { HubSpotCTAProps } from 'components/common/RichText/HubSpotCTA';
import { ShareButtons } from 'components/common/ShareButtons';
import { formatDate } from 'utils/formatDate';
import { useAnchorsObserver } from 'lib/hooks/useAnchorsObserver';
import { BlogArticleTags } from '../common/BlogArticleCategories';
import BlogCategory = Storyblok.BlogCategory;

const OBSERVED_ANCHOR_CLASS_NAME = 'article-anchor';

type ArticleSectionProps = {
  metadata: Storyblok.Metadata;
  title: string;
  image: Storyblok.Image;
  author: Storyblok.Author;
  date: string;
  content: Storyblok.RichText;
  anchorHeadings: {
    id: string;
    name: string;
  }[];
  customAnchorHeadings: {
    url: string;
    newName: string;
  }[];
  leftSidebarTitle: string;
  rightSidebarCallToActions: HubSpotCTAProps[];
  latestArticlesTitle: string;
  latestArticles: { slug: string; content: { title: string; date: string; primaryCategory?: { slug: string } } }[];
  primaryCategory: BlogCategory;
  otherCategories?: BlogCategory[];
};

export default function ArticleSection({
  metadata,
  title,
  image,
  author,
  date,
  content,
  anchorHeadings,
  customAnchorHeadings,
  leftSidebarTitle,
  rightSidebarCallToActions,
  latestArticlesTitle,
  latestArticles,
  primaryCategory,
  otherCategories,
}: ArticleSectionProps) {
  const { locale } = useTranslation();
  const currentAnchorId = useAnchorsObserver(OBSERVED_ANCHOR_CLASS_NAME);

  return (
    <Container as="article">
      <ThreeColumnGrid
        center={
          <>
            <Heading as="h1" size="2xl">
              {title}
            </Heading>
            <BlogArticleTags mt="16px" primaryCategory={primaryCategory} otherCategories={otherCategories} />
            <Author mt="40px" mb="32px" author={author} date={date} locale={locale as Locale} />
          </>
        }
      />
      <ThreeColumnGrid
        left={
          <Summary
            activeItemId={currentAnchorId}
            title={leftSidebarTitle}
            listItems={anchorHeadings}
            customListItems={customAnchorHeadings}
          />
        }
        center={
          <>
            <Box as="figure" width="100%" pt="43.3%" borderRadius="8px" overflow="hidden" position="relative" mb="32px">
              <Image src={image.filename} alt="" layout="fill" objectFit="cover" sizes="720px" priority />
            </Box>
            <RichText
              anchorClassName={OBSERVED_ANCHOR_CLASS_NAME}
              content={content}
              anchorHeadings
              textFontFamily="reading"
              textColor="sapphire.100"
              _ul={ulStyles}
              _ol={olStyles}
              _p={{ whiteSpace: 'pre-line' }}
            />
          </>
        }
        right={
          <Grid gridGap="16px">
            {rightSidebarCallToActions?.length > 0 && <CallToActions callToActions={rightSidebarCallToActions} />}
            {latestArticles?.length > 0 && (
              <LatestArticles latestArticlesTitle={latestArticlesTitle} latestArticles={latestArticles} />
            )}
            <ShareButtons title={metadata.title} description={metadata.description} />
          </Grid>
        }
      />
    </Container>
  );
}

type ThreeColumnGridProps = {
  left?: ReactNode;
  center: ReactNode;
  right?: ReactNode;
};

const ThreeColumnGrid: VFC<ThreeColumnGridProps> = ({ left = null, center, right = null }) => {
  return (
    <Grid
      gridTemplateColumns={{
        base: '0 minmax(auto, 720px) 0',
        desktop: '0 minmax(auto, 720px) 240px',
        large: '240px minmax(auto, 720px) 240px',
      }}
      gridGap={{ desktop: '24px', large: '40px' }}
      justifyContent="center"
      alignItems="start"
      position="relative"
    >
      <Box position="sticky" top={`calc(${headerHeight.desktop}px + 24px)`}>
        <Box display={{ base: 'none', large: 'block' }}>{left}</Box>
      </Box>
      <Box>{center}</Box>
      <Box position="sticky" top={`calc(${headerHeight.desktop}px + 24px)`}>
        <Box display={{ base: 'none', desktop: 'block' }}>{right}</Box>
      </Box>
    </Grid>
  );
};

const Summary: VFC<any> = ({ activeItemId, title, listItems, customListItems }) => {
  const ref = useRef(null);

  const visibleItems = listItems.filter((listItem) => {
    const customListItem = getCorrespondingCustomListItem(listItem.id, customListItems);
    return !customListItem?.hide;
  });

  const activeItemIndex = Math.max(
    0,
    visibleItems.findIndex((listItem) => listItem.id === activeItemId),
  );

  const activeSummaryItem = ref.current?.childNodes[activeItemIndex + 1];

  if (visibleItems.length === 0) {
    return null;
  }

  return (
    <>
      <Heading as="p" size="sm" mb="12px">
        {title}
      </Heading>
      <Grid gridGap="8px" position="relative" ref={ref}>
        <Box
          as="span"
          height={`${activeSummaryItem?.clientHeight ?? 0}px`}
          bg="azure.100"
          width="4px"
          borderRadius="2px"
          position="absolute"
          left="-24px"
          top={`${activeSummaryItem?.offsetTop ?? 0}px`}
          transition="all .5s cubic-bezier(.215,.61,.355,1)"
        />
        {visibleItems.map((listItem) => {
          const customListItem = getCorrespondingCustomListItem(listItem.id, customListItems);
          const isSelected = activeItemId === listItem.id;

          return (
            <NextLink key={listItem.id} href={`#${listItem.id}`} passHref>
              <Button
                as="a"
                variant="link"
                color={isSelected ? 'sapphire.100' : 'sapphire.50'}
                fontWeight="normal"
                fontSize="sm"
                lineHeight="20px"
                textAlign="left"
                justifyContent="flex-start"
                whiteSpace="normal"
                noOfLines={2}
              >
                {customListItem?.newName || listItem.name}
              </Button>
            </NextLink>
          );
        })}
      </Grid>
    </>
  );
};

function getCorrespondingCustomListItem(id, customListItems) {
  return customListItems?.find((customListItem) => customListItem.url.split('#')[1] === id);
}

type LatestArticlesProps = Pick<ArticleSectionProps, 'latestArticlesTitle' | 'latestArticles'>;

function LatestArticles({ latestArticlesTitle, latestArticles }: LatestArticlesProps) {
  const { locale } = useTranslation();

  return (
    <Box p="16px" borderRadius="8px" boxShadow="lg">
      <Heading as="p" size="xs" mb="16px">
        {latestArticlesTitle}
      </Heading>
      <VStack divider={<Divider bg="neutral.100" />} spacing="16px" align="left">
        {latestArticles.map((article, i) => {
          // Replace space before punctuation mark with a non-breakable space
          const normalizedTitle = article.content.title.replace(/(.+)( )([?!.])$/, '$1\u00a0$3');
          return (
            <LinkBox
              key={i}
              borderRadius="8px"
              sx={{ _hover: { bg: 'azure.8' } }}
              margin="-8px"
              padding="8px"
              transition="background-color 200ms ease-out"
            >
              <NextLink href={`/blog/${article.content.primaryCategory.slug}/${article.slug}`} passHref>
                <LinkOverlay fontSize="14px" lineHeight="20px">
                  {normalizedTitle}
                </LinkOverlay>
              </NextLink>
              <Text fontSize="12px" lineHeight="1" mt="8px">
                {formatDate(article.content.date, 'PP', locale)}
              </Text>
            </LinkBox>
          );
        })}
      </VStack>
    </Box>
  );
}

type CallToActionsProps = {
  callToActions: HubSpotCTAProps[];
};

function CallToActions({ callToActions }: CallToActionsProps) {
  return (
    <Grid>
      {callToActions.map((callToAction, i) => {
        return <HubSpotCTA key={i} {...callToAction} />;
      })}
    </Grid>
  );
}

const listStyles = {
  paddingLeft: '1em',
  fontSize: {
    base: '16px',
    tablet: '20px',
  },
  lineHeight: {
    base: '26px',
    tablet: '34px',
  },
  marginBottom: {
    base: '24px',
    tablet: '32px',
  },
  li: {
    p: { margin: 0 },
    listStylePosition: 'outside',
    padding: 0,
    _notFirst: {
      marginTop: '16px',
    },
  },
};

const ulStyles = {
  ...listStyles,
  listStyle: 'disc',
};

const olStyles = { ...listStyles };
