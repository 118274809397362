import { Button, ButtonProps, Stack, StackProps } from '@chakra-ui/react';
import { LinkRouter } from 'components/common/link-router';
import { Storyblok } from 'types';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

type ButtonGroupProps = {
  buttons: Storyblok.Button[];
  _button?: ButtonProps;
};

export function ButtonGroup({
  buttons,
  direction = { base: 'column', tablet: 'row' },
  alignItems = 'center',
  width = { base: '100%', tablet: 'auto' },
  _button,
  ...rest
}: ButtonGroupProps & StackProps) {
  return (
    <Stack direction={direction} alignItems={alignItems} {...rest}>
      {buttons?.map((button, i) => (
        <LinkRouter key={i} href={button.link.url} width={width}>
          <Button
            variant={button.variant}
            size={button.size}
            colorScheme={button.colorScheme}
            leftIcon={button.leftIcon && <DynamicIcon icon={button.leftIcon} />}
            rightIcon={button.rightIcon && <DynamicIcon icon={button.rightIcon} />}
            width={width}
            {..._button}
          >
            {button.text}
          </Button>
        </LinkRouter>
      ))}
    </Stack>
  );
}
