import BlogNavigation from 'components/common/BlogNavigation';
import Grid from 'components/common/Grid';
import RichTextContent from 'components/common/RichText';
import BlogCategoryHeader from 'components/sections/BlogCategoryHeader';
import GridFour from 'components/sections/GridFour';
import SbEditable from 'storyblok-react';
import Article from './Article';
import Articles from './Articles';
import BeforeAfter from './BeforeAfter';
import BrandAssetsSection from './BrandAssets';
import CallToActionOne from './CallToActionOne';
import CarouselOne from './CarouselOne';
import CategoryHero from './CategoryHero';
import CompanyDetails from './CompanyDetails';
import ContentCard from './ContentCard';
import CostEstimatorSection from './CostEstimatorSection/CostEstimatorSection';
import Customers from './Customers';
import CustomerStoriesBodySection from './CustomerStoriesBodySection';
import CustomerStoriesHero from './CustomerStoriesHero';
import Divider from './Divider';
import Error404 from './Error404';
import Faq from './Faq';
import FeatureOneSection from './FeatureOne';
import FeatureThreeSection from './FeatureThree';
import FeatureTwo from './FeatureTwo';
import FloatingLogos from './FloatingLogos';
import GridOne from './GridOne';
import GridThree from './GridThree';
import GridTwo from './GridTwo';
import HeroOne from './HeroOne';
import HeroThree from './HeroThree';
import HeroTwo from './HeroTwo';
import JobPosts from './JobPosts';
import PageSuggestionsSection from './PageSuggestions';
import PricingHero from './PricingHero';
import ProductTourSection from './ProductTour';
import Quote from './Quote';
import QuoteTwo from './QuoteTwo';
import Reviews from './Reviews';
import RichText from './RichText';
import ScrollGallery from './ScrollGallery';
import SlideshowSection from './Slideshow';
import StatisticsGroup from './StatisticsGroup';
import StickyNavContent from './StickyNavContent';
import TestimonialOne from './TestimonialOne';
import TestimonialsGridSection from './TestimonialsGrid';
import TestimonialTwoSection from './TestimonialTwo';
import Timeline from './Timeline';
import TranslatableSections from './TranslatableSections';
import VideoOneSection from './VideoOne';
import VideoTwoSection from './VideoTwo';
import { IFrame } from './IFrame';

// This is a function to avoid circular dependency with grid component
const resolveComponent = (componentName: string) => {
  const mapping = {
    richText: RichText,
    heroOne: HeroOne,
    heroTwo: HeroTwo,
    customers: Customers,
    featureOne: FeatureOneSection,
    featureTwo: FeatureTwo,
    featureThree: FeatureThreeSection,
    callToActionOne: CallToActionOne,
    timeline: Timeline,
    testimonialOne: TestimonialOne,
    testimonialTwo: TestimonialTwoSection,
    articleBody: Article,
    slideshow: SlideshowSection,
    brandAssets: BrandAssetsSection,
    pageSuggestions: PageSuggestionsSection,
    companyDetails: CompanyDetails,
    scrollGallery: ScrollGallery,
    quote: Quote,
    gridOne: GridOne,
    gridTwo: GridTwo,
    gridFour: GridFour,
    carouselOne: CarouselOne,
    jobPosts: JobPosts,
    error404: Error404,
    articles: Articles,
    stickyNavContent: StickyNavContent,
    contentCard: ContentCard,
    faq: Faq,
    gridThree: GridThree,
    reviews: Reviews,
    floatingLogos: FloatingLogos,
    translatableSections: TranslatableSections,
    divider: Divider,
    heroThree: HeroThree,
    costEstimator: CostEstimatorSection,
    productTour: ProductTourSection,
    testimonialsGrid: TestimonialsGridSection,
    videoOne: VideoOneSection,
    videoTwo: VideoTwoSection,
    blogNavigation: BlogNavigation,
    blogCategoryHeader: BlogCategoryHeader,
    customerStoriesHero: CustomerStoriesHero,
    statisticsGroup: StatisticsGroup,
    customerStoriesBodySection: CustomerStoriesBodySection,
    beforeAfter: BeforeAfter,
    quoteTwo: QuoteTwo,
    pricingHero: PricingHero,
    grid: Grid,
    richTextContent: RichTextContent,
    categoryHero: CategoryHero,
    iframe: IFrame,
  };
  return mapping[componentName];
};

export default function Sections({ sections }) {
  return sections.map((section) => <DynamicComponent key={section._uid} block={section} />);
}

export const DynamicComponent = ({ block }) => {
  const Component = resolveComponent(block.component);

  if (!Component) {
    return (
      <p>
        The component <strong>{block.component}</strong> has not been created yet.
      </p>
    );
  }

  return (
    <SbEditable content={block}>
      <Component {...block} />
    </SbEditable>
  );
};
