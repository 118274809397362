import { useEffect, VFC } from 'react';
import { Box } from '@chakra-ui/react';

export type HubSpotCTAProps = {
  id: string;
  alt: string;
};

const HUBSPOT_PORTAL_ID = Number(process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID);

const HubSpotCTA: VFC<HubSpotCTAProps> = ({ id, alt }) => {
  useEffect(() => {
    window.hbspt?.cta?.load(HUBSPOT_PORTAL_ID, id, { useNewLoader: 'true', region: 'na1' });
  }, []);

  return (
    <Box textAlign="center">
      <span className="hs-cta-wrapper" id={`hs-cta-wrapper-${id}`}>
        <span className={`hs-cta-node hs-cta-${id}`} id={`hs-cta-${id}`}>
          <a href={`https://cta-redirect.hubspot.com/cta/redirect/${HUBSPOT_PORTAL_ID}/${id}`}>
            <img
              className="hs-cta-img"
              id={`hs-cta-img-${id}`}
              style={{ borderWidth: 0 }}
              src={`https://no-cache.hubspot.com/cta/default/${HUBSPOT_PORTAL_ID}/${id}.png`}
              alt={alt}
              loading="lazy"
            />
          </a>
        </span>
      </span>
    </Box>
  );
};

export default HubSpotCTA;
