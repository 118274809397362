import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  LinkBox,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDown } from 'components/common/icons/assets/ChevronDown';
import { containerStyle, descriptionStyle, layerStyles, titleStyle } from './styles';
import NextLink from 'next/link';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import SmartLinkOverlay from 'components/common/SmartLinkOverlay';

export default function DesktopMenu({ text, menuGroups, menuFooterItems }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex {...containerStyle} onMouseEnter={onOpen} onMouseLeave={onClose}>
      <Menu isOpen={isOpen} autoSelect={false}>
        <MenuButton
          isActive={isOpen}
          as={Button}
          variant="link"
          colorScheme="sapphireAzure"
          fontWeight="normal"
          rightIcon={<Icon as={ChevronDown} fontSize="14px" ml="4px" className="animated-icon-rotate" />}
          color={isOpen ? 'azure.100' : 'sapphire.100'}
        >
          {text}
        </MenuButton>
        <MenuList mt="10px" onMouseEnter={onOpen} onMouseLeave={onClose}>
          <Grid gridTemplateColumns={`repeat(${menuGroups.length}, 1fr)`} gridColumnGap="12px">
            {menuGroups.map((menuGroup, i) => (
              <MenuGroup key={i} title={menuGroup.title}>
                <Grid gridRowGap="2px">
                  {menuGroup.menuItems.map((menuItem, i) => {
                    if (!menuItem.link.url) {
                      return null;
                    }
                    return (
                      <LinkBox key={i}>
                        <MenuItem
                          onClick={onClose}
                          display="grid"
                          gridGap="16px"
                          gridTemplateColumns="auto 1fr"
                          p="12px"
                          borderRadius="4px"
                          minWidth="360px"
                          transition="background-color 200ms ease-out 0s"
                          {...layerStyles[menuItem.layerStyle]?.container}
                        >
                          <Box>
                            {menuItem.leftIcon && (
                              <Flex
                                width="40px"
                                height="40px"
                                borderRadius="full"
                                alignItems="center"
                                justifyContent="center"
                                className="icon-container"
                                transition="background-color 200ms ease-out 0s"
                                {...layerStyles[menuItem.layerStyle]?.iconContainer}
                              >
                                <DynamicIcon
                                  icon={menuItem.leftIcon}
                                  fontSize="24px"
                                  className="icon"
                                  transition="fill 200ms ease-out 0s"
                                  {...layerStyles[menuItem.layerStyle]?.icon}
                                />
                              </Flex>
                            )}
                          </Box>
                          <Grid gridGap="6px">
                            <Flex {...titleStyle} alignItems="center">
                              <SmartLinkOverlay href={menuItem.link.url}>{menuItem.title}</SmartLinkOverlay>
                              {menuItem.tag && (
                                <Tag size="sm" ml="2" className="tag" {...layerStyles[menuItem.layerStyle]?.tag}>
                                  {menuItem.tag}
                                </Tag>
                              )}
                            </Flex>
                            <Box {...descriptionStyle}>{menuItem.description}</Box>
                          </Grid>
                        </MenuItem>
                      </LinkBox>
                    );
                  })}
                </Grid>
              </MenuGroup>
            ))}
          </Grid>
          {menuFooterItems.length > 0 && (
            <Grid mx="-12px" mt="12px" mb="-12px" p="24px" borderTop="1px solid" borderColor="rgb(238, 240, 243)">
              {menuFooterItems.map((menuFooterItem, i) => (
                <Grid key={i} gridGap="16px" gridTemplateColumns="auto 1fr auto">
                  <Box>
                    <Flex
                      width="40px"
                      height="40px"
                      borderRadius="full"
                      alignItems="center"
                      justifyContent="center"
                      bg="azure.100"
                    >
                      <DynamicIcon icon={menuFooterItem.icon} fontSize="24px" color="white" />
                    </Flex>
                  </Box>
                  <Grid gridGap="6px">
                    <Box {...titleStyle}>{menuFooterItem.title}</Box>
                    <Box {...descriptionStyle}>{menuFooterItem.description}</Box>
                  </Grid>
                  <Flex>
                    <NextLink href={menuFooterItem.link.url}>
                      <Button onClick={onClose} colorScheme="lightAzure">
                        {menuFooterItem.buttonText}
                      </Button>
                    </NextLink>
                  </Flex>
                </Grid>
              ))}
            </Grid>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}
